import { FunctionComponent } from 'react';
import { Option } from '@spike/model';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    makeStyles,
    createStyles,
    Theme,
    useMediaQuery,
    useTheme,
    Typography,
    Box
} from '@material-ui/core';
import { Spinner } from 'components/UI';
import { Checkbox } from 'components/UI';
import { SelectField } from 'components/UI';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/UI/V2/Button/Button';

interface CancellationModalProps {
    title: string;
    checkboxLabel: string;
    question: string;
    reasons: Array<Option<string | number>>;
    checked: boolean;
    isOpen: boolean;
    loading: boolean;
    disabled: boolean;
    slot?: React.ReactNode;
    onChecked?: (checked: boolean) => void;
    onSelectReason?: (selectedField: Option<string | number> | undefined) => void;
    onConfirm?: () => void;
    onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'position': 'relative',
            '& .MuiDialog-paper': {
                minWidth: 400,
                minHeight: 300,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center'
            },
            '&   .MuiDialog-paper > .MuiBox-root': {
                minWidth: 400,
                minHeight: 300,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: '100%'
                }
            },
            [theme.breakpoints.down('sm')]: {
                'height': '100%',
                'width': '100%',
                'display': 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'flexDirection': 'column',
                '& .MuiDialog-container': {
                    width: '100%'
                }
            }
        },
        body: {
            padding: '60px 70px 0px 70px',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                justifyContent: 'center'
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0 30px'
            }
        },
        title: {
            '& .MuiTypography-root': {
                fontWeight: 600,
                textAlign: 'center',
                [theme.breakpoints.down('sm')]: {
                    padding: 0,
                    fontSize: 26
                }
            }
        },
        questionContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        question: {
            marginBottom: 28,
            fontSize: 16,
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: 16,
                maxWidth: 260
            }
        },
        select: {
            'marginBottom': 30,
            [theme.breakpoints.down('sm')]: {
                marginTop: 10
            },
            '& > div:first-child': {
                'margin': 0,
                'padding': 0,
                'marginBottom': 4,
                '& > .MuiTypography-root': {
                    fontWeight: 600
                }
            }
        },
        checkbox: {
            'marginBottom': 30,
            'color': '#7A7A7A',
            [theme.breakpoints.down('sm')]: {
                marginTop: 10
            },
            '&.MuiFormControlLabel-root ': {
                alignItems: 'self-start'
            },
            '&.MuiFormControlLabel-root > .MuiTypography-root > .MuiTypography-root': {
                whiteSpace: 'wrap'
            }
        },
        footer: {
            width: '100%',
            borderTop: '1px solid #E0E0E0',
            padding: '0 70px',
            [theme.breakpoints.down('sm')]: {
                flex: 'none',
                gap: 10
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0 24px'
            }
        },
        footerActions: {
            gap: 10,
            padding: '8px 0px'
        },
        button: {
            'flex': 1,
            'height': 47,
            'marginLeft': '0px !important',
            '& > .MuiButton-label > span': {
                fontSize: 16,
                fontWeight: 600
            },
            [theme.breakpoints.down('sm')]: {
                height: 55
            }
        },
        closeIcon: {
            position: 'absolute',
            right: 35,
            top: 25,
            cursor: 'pointer',
            fontSize: 24,
            [theme.breakpoints.down('sm')]: {
                top: 15,
                right: 20
            }
        },
        buttonDisabled: {
            backgroundColor: '#D3D3D3 !important'
        }
    })
);

const CancellationModal: FunctionComponent<CancellationModalProps> = props => {
    const classes = useStyles();
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const closeHandler = () => {
        props.onClose && props.onClose();
    };

    const selectReasonHandler = (selectedField: Option<string | number>) => {
        props.onSelectReason && props.onSelectReason(selectedField);
    };

    const confirmHandler = () => {
        props.onConfirm && props.onConfirm();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={closeHandler}
            fullScreen={fullScreen}
            className={classes.root}
        >
            <FontAwesomeIcon
                icon={faXmark}
                className={classes.closeIcon}
                onClick={closeHandler}
            />
            {props.loading && <Spinner />}
            {!props.loading && (
                <DialogContent className={classes.body}>
                    <>
                        <DialogTitle className={classes.title}>{props.title}</DialogTitle>
                        <Box className={classes.questionContainer}>
                            <Typography className={classes.question}>{props.question}</Typography>
                        </Box>
                        {props.slot}
                        {props.onSelectReason && (
                            <SelectField
                                className={classes.select}
                                label="Cancellation reason"
                                placeholder="No reason provided"
                                options={props.reasons}
                                onSelect={selectedField => selectReasonHandler(selectedField)}
                            />
                        )}
                        {props.onChecked && (
                            <Checkbox
                                className={classes.checkbox}
                                checked={props.checked}
                                onChange={() => props.onChecked && props.onChecked(!props.checked)}
                                label={props.checkboxLabel}
                            />
                        )}
                    </>
                </DialogContent>
            )}
            {!props.loading && (
                <DialogContent className={classes.footer}>
                    <DialogActions className={classes.footerActions}>
                        <Button
                            label="No"
                            onClick={closeHandler}
                            variant="black"
                            size="large"
                            className={classes.button}
                        />
                        <Button
                            label="Yes"
                            onClick={confirmHandler}
                            variant="danger"
                            size="large"
                            className={classes.button}
                            isLoading={props.disabled}
                            disabled={props.disabled}
                        />
                    </DialogActions>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default CancellationModal;
