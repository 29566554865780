/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
import { FunctionComponent, useState } from 'react';
import { makeStyles, createStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Divider, Typography, useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { MarketplaceNotifications } from '@spike/marketplace-model';
import { OverFullWindow } from 'components/UI';
import Switch from 'components/UI/V2/Switch';
import { useNonInitialEffect } from '@versiondos/hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { BusinessSettingsTitle } from '../UI';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
    faClipboardCheck,
    faBell,
    faCheck,
    faBellConcierge,
    faCancel,
    faCheckCircle
} from '@fortawesome/pro-light-svg-icons';
import EditConfirmation from './Edit/EditConfirmation';
import { Option } from '@spike/model';
import EditAppointments from './Edit/EditAppointments';
import EditEmailContent from './Edit/EditEmailContent';
import { wbp } from 'Theme';
import SettingComponent from './UI/SettingComponent';

export interface Props {
    notifications: MarketplaceNotifications;
    onSave?: (notifications: MarketplaceNotifications) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '565px',
            paddingBottom: '20px',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                heigh: '100%'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        businessSettingsTittle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '16px'
            },
            [theme.breakpoints.down('sm')]: {
                'fontSize': '20px',
                'marginBottom': '5px',
                '& $iconBusinessTitle': {
                    fontSize: '20px',
                    fontWeight: '300'
                }
            }
        },
        businessSettingsTittleInternal: {
            fontSize: '20px !important'
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        subtitle: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '20px',
                marginTop: '12px'
            },
            fontSize: '16px',
            lineHeight: '17px',
            marginBottom: '30px'
        },
        subtitleReminders: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '100%',
            marginTop: '20px',
            marginBottom: '20px'
        },
        subtitleAppointments: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '100%',
            marginTop: '28px',
            marginBottom: '20px'
        },
        subtitleTerciary: {
            width: '100%',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '20px',
                marginTop: '12px'
            }
        },
        subtitleItem: {
            width: '100%',
            fontWeight: 400,
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%'
            }
        },
        containerItem: {
            fontSize: '16px',
            marginBottom: '15px',
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '7px'
            }
        },
        containerIconText: {
            'display': 'flex',

            '& a': {
                color: 'inherit',
                fontWeight: 600
            },

            [theme.breakpoints.down('sm')]: {
                '& $subtitle': {
                    marginTop: '-3px',
                    fontWeight: '400 !important',
                    paddingLeft: '10px',
                    fontSize: '14px',
                    maxWidth: '315px'
                }
            }
        },
        subtitleEmailAndSms: {
            width: '100%',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '17px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                lineHeight: '8px',
                marginBottom: '4px'
            }
        },
        subtitleEmailContent: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '17px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '10px',
                lineHeight: '8px',
                marginBottom: '4px'
            }
        },
        contentContainer: {
            'display': 'flex',
            'flexDirection': 'column',
            'width': '100%',
            '& .MuiAccordionDetails-root': {
                padding: '0px'
            }
        },
        contentContainerSwitch: {
            '& $switchComponent': {
                border: '1px solid #EFEFEF',
                borderRadius: '20px',
                padding: '10px',
                alignItems: 'center',
                justifyContent: 'start',
                gap: '20px',
                [theme.breakpoints.down('sm')]: {
                    'padding': '8px',
                    'gap': '2px',
                    '& p': {
                        fontSize: '16px',
                        lineHeight: '16px'
                    },
                    '& span': {
                        marginTop: '0px'
                    }
                }
            }
        },
        switchIconContainer: {
            backgroundColor: '#E4DDD5',
            borderRadius: '50px',
            width: '44px',
            height: '44px',
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'center'
        },
        switchIcon: {
            fontSize: '22px',
            fontWeight: 300
        },
        switchContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '15%',
            [theme.breakpoints.down('sm')]: {
                width: '5%'
            }
        },
        alertContainer: {
            width: '100%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 18,
                padding: 17,
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 20,
                padding: 15,
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        alertText: {
            color: '#000 !important',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '18px'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px',
                lineHeight: '22px'
            }
        },
        alertIcon: {
            color: '#BAA997',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            marginTop: '28px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '27px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '44px'
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                'marginTop': '20vh',
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '16px'
                    }
                }
            }
        },
        switchComponent: {
            'display': 'flex',
            'justifyContent': 'space-between',
            'borderBottom': '1px solid #EFEFEF',
            'cursor': 'pointer',
            'marginBottom': '22px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px'
            },
            '& .MuiSwitch-root': {
                [theme.breakpoints.down('sm')]: {
                    marginTop: '14px'
                },
                [theme.breakpoints.only('md')]: {
                    marginTop: '22px'
                },
                [theme.breakpoints.only('lg')]: {
                    marginTop: '2px'
                },
                [theme.breakpoints.only('xl')]: {
                    marginTop: '8px'
                }
            },
            '& .MuiTypography-root': {
                fontWeight: 500,
                fontSize: '16px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '6px',
                    lineHeight: '9px'
                }
            }
        },
        switchBookings: {
            paddingBottom: '20px'
        },
        switchComponentLast: {
            [theme.breakpoints.down('sm')]: {
                position: 'relative'
            }
        },
        leftContainer: {
            width: '90%',
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            [theme.breakpoints.down('sm')]: {
                width: '95%',
                gap: '8px'
            }
        },
        switchTitle: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '100%'
        },
        switchClientNotifications: {
            'display': 'flex',
            'paddingRight': '15px',
            'marginTop': '30px',
            'marginBottom': '15px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px'
            },

            '& .MuiTypography-root': {
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '21px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '10px',
                    lineHeight: '14px'
                }
            }
        },
        checkIcon: {
            [theme.breakpoints.up('sm')]: {
                marginRight: '5px',
                marginTop: '1px'
            },
            [theme.breakpoints.only('lg')]: {
                marginRight: '6px',
                marginTop: '2px'
            },
            [theme.breakpoints.only('xl')]: {
                marginRight: '7px',
                marginTop: '2px'
            }
        },
        emailContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '24px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '39px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '52px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '78px'
            }
        },

        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('sm')]: {
                display: 'block'
            }
        },
        EmailAndSmsContainerMobile: {
            marginTop: '30px',
            [theme.breakpoints.down('sm')]: {
                'borderRadius': '20px',

                '& $subtitleEmailAndSms': {
                    fontSize: '16px',
                    fontWeight: '500',
                    marginTop: '8px',
                    marginBottom: '24px'
                },
                '& $subtitleItem': {
                    paddingLeft: '10px',
                    fontSize: '16px',
                    lineHeight: '100%',
                    fontWeight: '600',
                    marginBottom: '8px'
                },
                '& $subtitle': {
                    maxWidth: '100%'
                }
            }
        },
        editOverWindow: {
            zIndex: 1002,
            borderBottom: 'none'
        },
        settingComponent: {
            marginBottom: '25px'
        },
        divider: {
            backgroundColor: '#D4D4D4',
            marginTop: '15px'
        },
        headerStyle: {
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none'
            }
        }
    })
);

export const Email: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const [shouldSave, setShouldSave] = useState(false);
    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    enum editScreens {
        Confirmation = 'Confirmation',
        Appointment = 'Appointment',
        EmailContent = 'EmailContent'
    }

    const [showEdit, setShowEdit] = useState<boolean | editScreens>(false);

    const [notifications, setNotifications] = useState<MarketplaceNotifications>({
        ...props.notifications,
        emailContent: props.notifications.emailContent.filter(notification => !isEmpty(notification))
    });

    const newBookingsSetting = notifications.settings.find(
        setting => setting.option.id == `business_new_bookings_notifications`
    );

    const appointmentCancellationsSetting = notifications.settings.find(
        setting => setting.option.id == `business_appointment_cancellations_notifications`
    );

    const clientNotificationsSetting = notifications.settings.find(
        setting => setting.option.id == `client_notifications`
    );

    useNonInitialEffect(() => {
        setNotifications(props.notifications);
    }, [props.notifications]);

    useNonInitialEffect(() => {
        const savedAux = isEqual(props.notifications, notifications);
        if (!savedAux && shouldSave) {
            handleSaveNotifications();
            setShouldSave(false);
        }
    }, [notifications]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setLoading(false);
            setShowEdit(false);
        }
    }, [status]);

    const handleEditMailContent = (notifications: MarketplaceNotifications) => {
        setShouldSave(false);
        setNotifications(notifications);
    };

    const handleSaveNotifications = () => {
        const formatNotifications = {
            ...notifications,
            emailContent: notifications.emailContent.filter(notification => !isEmpty(notification.trim()))
        };
        setLoading(true);
        props.onSave && props.onSave(formatNotifications);
    };

    const handleEditSetting = (emailActive: boolean, smsActive: boolean, notice: Option<string>, key: string) => {
        const updatedSettings = notifications.settings.map(setting => {
            if (setting.option.id === `${key}_email`) {
                return { ...setting, active: emailActive };
            }
            if (setting.option.id === `${key}_sms`) {
                return { ...setting, active: smsActive };
            }
            if (setting.option.id === `${key}_notice`) {
                return { ...setting, reminderTime: notice };
            }
            return setting;
        });

        setNotifications(prev => ({
            ...prev,
            settings: updatedSettings
        }));
        setShouldSave(false);
    };

    const switchChanged = (name: string, checked: boolean, key: string) => {
        const updatedSettings = notifications.settings.map(setting =>
            setting.option.id === key ? { ...setting, active: checked } : setting
        );

        setShouldSave(true);
        setNotifications(prev => ({
            ...prev,
            [name]: checked,
            settings: updatedSettings
        }));
    };

    const backHandler = () => {
        setShowEdit(false);
        setShouldSave(false);
        setNotifications({
            ...props.notifications,
            emailContent: props.notifications.emailContent.filter(notification => !isEmpty(notification))
        });
    };

    const editView = (
        <OverFullWindow
            hideHeader={false}
            showFooter={true}
            title={showEdit !== editScreens.EmailContent ? 'Edit Notification' : 'Preview - Client Instructions'}
            className={classes.editOverWindow}
            centered={true}
            onClose={backHandler}
            onBack={showEdit !== editScreens.EmailContent ? undefined : backHandler}
            onSave={() => handleSaveNotifications()}
            headerClassName={classes.headerStyle}
        >
            {showEdit == editScreens.Confirmation && (
                <EditConfirmation
                    onEdit={handleEditSetting}
                    loading={loading}
                />
            )}
            {showEdit == editScreens.Appointment && (
                <EditAppointments
                    onEdit={handleEditSetting}
                    loading={loading}
                />
            )}
            {showEdit == editScreens.EmailContent && (
                <EditEmailContent
                    notifications={notifications}
                    loading={loading}
                    onEdit={handleEditMailContent}
                />
            )}
        </OverFullWindow>
    );

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle className={classes.businessSettingsTittle}>
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}{' '}
                    Business Notifications
                </BusinessSettingsTitle>
                <Typography className={classes.subtitle}>
                    Select which e-mail notifications you would like to receive related to your business activity on
                    Cuddles.
                </Typography>
            </Box>
            <Box className={clsx(classes.contentContainer, classes.contentContainerSwitch)}>
                <Box className={clsx(classes.switchComponent, classes.switchBookings)}>
                    <Box className={classes.leftContainer}>
                        <Box className={classes.switchIconContainer}>
                            <FontAwesomeIcon
                                className={classes.switchIcon}
                                icon={faBellConcierge}
                            />
                        </Box>
                        <Typography className={classes.switchTitle}>New Bookings</Typography>
                    </Box>
                    <Box className={classes.switchContainer}>
                        {' '}
                        <Switch
                            checked={newBookingsSetting ? newBookingsSetting.active : notifications.newBookings}
                            onCheck={(name, checked) =>
                                switchChanged(name, checked, 'business_new_bookings_notifications')
                            }
                            name="newBookings"
                        />
                    </Box>
                </Box>
                <Box className={clsx(classes.switchComponent, classes.switchBookings)}>
                    <Box className={classes.leftContainer}>
                        <Box className={classes.switchIconContainer}>
                            <FontAwesomeIcon
                                className={classes.switchIcon}
                                icon={faCancel}
                            />
                        </Box>
                        <Typography className={classes.switchTitle}> Appointment Cancellations</Typography>
                    </Box>
                    <Box className={classes.switchContainer}>
                        <Switch
                            checked={
                                appointmentCancellationsSetting
                                    ? appointmentCancellationsSetting.active
                                    : notifications.appointmentCancellations
                            }
                            onCheck={(name, checked) =>
                                switchChanged(name, checked, 'business_appointment_cancellations_notifications')
                            }
                            name="appointmentCancellations"
                        />
                    </Box>
                </Box>

                <Divider className={classes.divider} />

                <Box className={classes.separator}></Box>
                <Box className={classes.switchClientNotifications}>
                    <Box className={classes.leftContainer}>
                        <Typography className={classes.businessSettingsTittleInternal}>Client Notifications</Typography>
                    </Box>
                    <Box className={classes.switchContainer}>
                        <Switch
                            checked={
                                clientNotificationsSetting ? clientNotificationsSetting.active : notifications.client
                            }
                            onCheck={(name, checked) => switchChanged(name, checked, 'client_notifications')}
                            name="client"
                        />
                    </Box>
                </Box>
                <Box className={classes.alertContainer}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={classes.alertIcon}
                    />
                    <Box>
                        <Typography className={classes.alertText}>
                            SMS Notifications only applies for  <Link to="/business_settings/info/plans">Premium</Link>{' '}
                            members.
                        </Typography>
                    </Box>
                </Box>
                <Box className={classes.contentContainer}>
                    <Typography className={classes.subtitleReminders}>
                        {isMobile ? 'E-mail & SMS sequence' : 'Reminders'}
                    </Typography>
                    <Typography className={classes.subtitleItem}>
                        {
                            'Choose when and how your customers receive confirmations and reminders, and personalize the instructions for preparing for their appointments.'
                        }
                    </Typography>

                    <Box className={classes.EmailAndSmsContainerMobile}>
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'Confirmation Request'}
                            subtitle={'Requests unconfirmed clients to confirm their upcoming appointment.'}
                            headerIcon={faClipboardCheck}
                            optionId={'client_confirmation_request'}
                            showEmail={true}
                            showSms={true}
                            onEdit={() => setShowEdit(editScreens.Confirmation)}
                        />
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'Appointment Reminder'}
                            subtitle={'Reminds clients of their upcoming appointment.'}
                            headerIcon={faBell}
                            optionId={'client_appointment_reminder'}
                            showEmail={true}
                            showSms={true}
                            onEdit={() => setShowEdit(editScreens.Appointment)}
                        />
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'Client Instructions'}
                            subtitle={'Sends instructions after the appointment is confirmed.'}
                            headerIcon={faCheck}
                            optionId={'client_instructions'}
                            showEmail={true}
                            showSms={false}
                            onEdit={() => setShowEdit(editScreens.EmailContent)}
                        />
                        <Typography className={classes.subtitleAppointments}>{'Appointments'}</Typography>
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'New Booking'}
                            subtitle={'Sends immediately after a new appointment is booked.'}
                            headerIcon={faBellConcierge}
                            optionId={'client_new_booking'}
                            showEmail={true}
                            showSms={true}
                        />
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'Canceled Appointment'}
                            subtitle={'Alerts client when their appointment has been canceled.'}
                            headerIcon={faCancel}
                            optionId={'client_cancelled_appointment'}
                            showEmail={true}
                            showSms={false}
                        />
                        <SettingComponent
                            className={classes.settingComponent}
                            title={'Completed Appointment'}
                            subtitle={'Informs clients when their pet is ready for pick-up.'}
                            headerIcon={faCheckCircle}
                            optionId={'client_completed_appointment'}
                            showEmail={false}
                            showSms={true}
                        />
                    </Box>
                </Box>
            </Box>

            {showEdit && editView}
        </Box>
    );
};

export default Email;
